import React, {type FC, useState} from "react"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import {Puddleglum} from "../../puddleglum"
import GradientHeading from "../components/GradientHeading"

const LoginForm: FC = () => {
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [errorText, setErrorText] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const loginUser = async () => {
    try {
      setErrorText(undefined)
      setIsLoading(true)
      const response = await Puddleglum.Controllers.Auth.LoginController.login({
        email: email ?? "",
        password: password ?? "",
      })
      window.location.href = "/"
    } catch ({response}) {
      // @ts-ignore to be fixed in puddleglum, giving response is unknown
      setErrorText(response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      pos="fixed"
      top={0}
      right={0}
      left={0}
      bottom={0}
      bgImage="url(/images/login_background.png)"
      bgPos="center"
      bgSize="cover"
    >
      <Center w="100%" h="100vh">
        <Box
          w={{base: "90%", md: "50%", lg: "40%"}}
          bgColor="white"
          p={8}
          rounded="md"
          border="solid 1px"
          borderColor="gray.200"
          boxShadow="sm"
        >
          <GradientHeading>Log In</GradientHeading>
          {errorText && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{errorText}</AlertTitle>
            </Alert>
          )}

          <>
            <FormControl mt={2} id="email" isRequired>
              <FormLabel fontSize=".8em" color="gray.500">
                Email
              </FormLabel>
              <Input
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl mt={2} id="password" isRequired>
              <FormLabel fontSize=".8em" color="gray.500">
                Password
              </FormLabel>
              <Input
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            <Button
              type="submit"
              mt={4}
              colorScheme="blue"
              w="full"
              onClick={loginUser}
              isLoading={isLoading}
            >
              Login
            </Button>

            <Button variant="link" mt={2} w="full">
              Forgot Password?
            </Button>
          </>
        </Box>
      </Center>
    </Box>
  )
}

export default LoginForm
